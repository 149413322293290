import React from 'react';
import { useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import { Logo } from '../../shared/logo';
import './WeMovedPage.scss';

const newBaseUrl = 'https://survey-gridfit.griddynamics.net';

export const WeMovedPage = () => {
  const location = useLocation();
  return (
    <div className="not-found-page">
      <div className="not-found-page__img">
        <Logo />
      </div>
      <div className="not-found-page__title">We moved from this URL!</div>
      <div className="not-found-page__description">You can now find this page at:</div>
      <Button href={`${newBaseUrl}${location.pathname}`} variant="contained" color="primary">
        Take me to the new Url
      </Button>
    </div>
  );
};
