import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import { PrivateRoute } from './components/routes';
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage';
import { SignUpPage } from './pages/SignupPage/Signup';
import { DisciplinesPage } from './pages/DisciplinesPage/Disciplines';
import { Test } from './pages/TestPage/Test';
import { useStores } from './custom-hooks/store/use-stores';
import { TestSummary } from './pages/TestSummaryPage/TestSummaryPage';
import { EducationTaskPage } from './pages/EducationTaskPage/EducationTaskPage';
import { WeMovedPage } from './pages/WeMovedPage/WeMovedPage';

export const App = observer(() => {
  const {
    userActions: {
      getUserProfile,
    },
    userStore: {
      isAuthenticated,
    },
  } = useStores();

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  if (isAuthenticated === null) {
    return null;
  }

  return (
    <Switch>
      <Route path="*" component={WeMovedPage} />
      <PrivateRoute exact path="/disciplines" component={() => <DisciplinesPage />} />
      <PrivateRoute path="/test/:testId" component={Test} />
      <Route path="/signup/:assessmentId?" component={SignUpPage} />
      <Route path="/404" component={() => <NotFoundPage />} />
      <Route path="/education/:testId" component={() => <TestSummary />} />
      <Route path="/screening/:testId" component={() => <TestSummary />} />
      <Route path="/education-tasks/:testId" component={() => <EducationTaskPage />} />
      <Redirect from="*" to="/404" />
    </Switch>
  );
});
